import type { AzureADProfile } from 'next-auth/providers/azure-ad'
import type { JWT } from 'next-auth/jwt'
import type { NextAuthOptions } from 'next-auth'
import AzureADProvider from 'next-auth/providers/azure-ad'
import { mapUserRolesTokenToUserRoles } from '../shared/utils/auth'
import { featurePermissions, contentPermissions } from '../shared/constants/permissions'
import { staticGetUserByEmail } from '@/lib/hooks/server'

export type GhostUserAction = Auth.NextAuthAction<'ghostUser', DTO.UserWithPermissions>
const isGhostUserSession = (session: unknown): session is GhostUserAction =>
    typeof session === 'object' && session !== null && 'action' in session && session.action === 'ghostUser'

const featurePermissionIdLookup = new Map(featurePermissions.map((f) => [f.slug, f.id]))
const contentPermissionIdLookup = new Map(contentPermissions.map((c) => [c.slug, c.id]))
const getAuthTokenRoles = (sessionRoles: Auth.UserRoles): Auth.UserRolesToken => ({
    featurePermissions: Object.keys(sessionRoles.featurePermissions)
        .map((slug) => featurePermissionIdLookup.get(slug as Enum.FeaturePermissionType))
        .filter((x) => x) as Array<number>, // handle edge case where user logs in after seed but before code deployment
    contentPermissions: Object.keys(sessionRoles.contentPermissions)
        .map((slug) => contentPermissionIdLookup.get(slug as Enum.ContentPermissionType))
        .filter((x) => x) as Array<number>, // handle edge case where user logs in after seed but before code deployment
})

export const authOptions: NextAuthOptions = {
    secret: process.env.NEXTAUTH_SECRET,
    providers: [
        AzureADProvider<AzureADProfile>({
            clientId: process.env.AZURE_AD_CLIENT_ID,
            clientSecret: process.env.AZURE_AD_CLIENT_SECRET,
            tenantId: process.env.NEXT_PUBLIC_AZURE_AD_TENANT_ID,
        }),
    ],
    callbacks: {
        // https://next-auth.js.org/configuration/callbacks#sign-in-callback
        async signIn(params): Promise<boolean> {
            const { user } = params
            let databaseUser: DTO.UserWithPermissions | undefined
            if (user.email) {
                databaseUser = await staticGetUserByEmail(encodeURIComponent(user.email))

                user.roles = {
                    featurePermissions: { ...databaseUser.featurePermissions },
                    contentPermissions: { ...databaseUser.contentPermissions },
                }
                user.userId = databaseUser.id
                user.entityId = databaseUser.entityId
                delete user.image
            }
            return databaseUser?.status === 'active'
        },
        // https://next-auth.js.org/configuration/callbacks#jwt-callback
        jwt(params): JWT {
            const {
                token: { picture, ...token },
                account,
                user,
                trigger,
            } = params
            if (trigger === 'update' && isGhostUserSession(params.session)) {
                const session = params.session as { action: 'ghostUser'; data: DTO.UserWithPermissions }
                // always add site-management role to have access to the site-management controls
                session.data.featurePermissions['site-management'] = true

                token.roles = getAuthTokenRoles(session.data)
                token.name = `${session.data.firstName} ${session.data.lastName}`
                token.email = session.data.email
                token.userId = session.data.id
                token.entityId = session.data.entityId
                token.isGhostUser = true

                return token
            }

            const isNewSession = !!account
            if (isNewSession) {
                token.roles = {
                    ...user.roles,
                    ...getAuthTokenRoles(user.roles),
                }
                token.accessToken = account.access_token
                token.userId = user.userId
                token.entityId = user.entityId
                delete user.image
            }

            return token
        },
        // https://next-auth.js.org/configuration/callbacks#session-callback
        session(params) {
            const { session, token } = params
            session.roles = mapUserRolesTokenToUserRoles(token.roles)
            session.userId = token.userId
            session.entityId = token.entityId
            if (session.user) session.user.name = token.name
            if (session.user?.email) session.user.email = token.email
            if (token.isGhostUser) session.isGhostUser = token.isGhostUser
            delete session.user?.image
            return session
        },
    },
    pages: {
        signIn: '/auth/signin',
        error: '/error/500',
    },
}
