import type { ShotFilters } from '../definitions/dto/Shot'
import type {
    ShotLevel,
    ShotType,
    ReleaseHand,
    ShotJumpCategory,
    ShotJumpFootingTakeoff,
    ShotJumpFootingLanding,
    ComplexShotType,
    AroundRimFinishType,
    ContestLevel,
    ComplexOutcome,
    PlayType,
    ShotZone,
} from '../definitions/enum/ShotType'
import type { League } from '../definitions/enum/Games'
import type { GameState } from '../definitions/enum/Stats'
import type { TeamStatsType, TimeFrame } from '../definitions/dto/TeamStats'

export type ShotFilterQueryParams = {
    [Filter in keyof ShotFilters]?: string | string[]
}

function parseArrayTypes<T extends string = string>(
    input: string | string[] | undefined,
    excludeEmptyArrays: false
): T[]
function parseArrayTypes<T extends string = string>(
    input: string | string[] | undefined,
    excludeEmptyArrays: boolean
): T[] | undefined
function parseArrayTypes<T extends string = string>(
    input: string | string[] | undefined,
    excludeEmptyArrays: boolean
): T[] | undefined {
    const value: T[] = Array.isArray(input) ? (input as T[]) : input ? [input as T] : []
    return excludeEmptyArrays && !value.length ? undefined : value
}

export const parseShotFilterQueryParams = (params: ShotFilterQueryParams, excludeEmptyArrays = true): ShotFilters => {
    const filters: Omit<ShotFilters, 'type' | 'playerType' | 'primaryDefId'> = {
        playerId: params.playerId as string,
        timeFrame: parseArrayTypes<TimeFrame>(params.timeFrame, false),
        level: params.level ? (params.level as ShotLevel) : 'NBA',
        seasons: parseArrayTypes(params.seasons, excludeEmptyArrays)?.map(Number),
        league: params.league ? (params.league as League) : undefined,
        shotTypes: parseArrayTypes<ShotType>(params.shotTypes, excludeEmptyArrays),
        startDate: params.startDate ? (params.startDate as string) : undefined,
        endDate: params.endDate ? (params.endDate as string) : undefined,
        gameStates: parseArrayTypes<GameState>(params.gameStates, excludeEmptyArrays),
        aroundTheRimFinish: parseArrayTypes<AroundRimFinishType>(params.aroundTheRimFinish, excludeEmptyArrays),
        contestLevel: parseArrayTypes<ContestLevel>(params.contestLevel, excludeEmptyArrays),
        complexOutcome: parseArrayTypes<ComplexOutcome>(params.complexOutcome, excludeEmptyArrays),
        dribblesTakenMin: params.dribblesTakenMin ? (params.dribblesTakenMin as string) : undefined,
        dribblesTakenMax: params.dribblesTakenMax ? (params.dribblesTakenMax as string) : undefined,
        previousEvent: parseArrayTypes<PlayType>(params.previousEvent, excludeEmptyArrays),
        releaseHand: parseArrayTypes<ReleaseHand>(params.releaseHand, excludeEmptyArrays),
        shotClockMin: params.shotClockMin ? (params.shotClockMin as string) : undefined,
        shotClockMax: params.shotClockMax ? (params.shotClockMax as string) : undefined,
        shotJumpCategory: parseArrayTypes<ShotJumpCategory>(params.shotJumpCategory, excludeEmptyArrays),
        shotJumpFootingTakeoff: parseArrayTypes<ShotJumpFootingTakeoff>(
            params.shotJumpFootingTakeoff,
            excludeEmptyArrays
        ),
        shotJumpFootingLanding: parseArrayTypes<ShotJumpFootingLanding>(
            params.shotJumpFootingLanding,
            excludeEmptyArrays
        ),
        shotTypeComplex: parseArrayTypes<ComplexShotType>(params.shotTypeComplex, excludeEmptyArrays),
        teamId: params.teamId ? (params.teamId as string) : undefined,
        gameIds: parseArrayTypes(params.gameIds, excludeEmptyArrays),
        playersToExclude: parseArrayTypes(params.playersToExclude, excludeEmptyArrays),
        opponentTeamId: params.opponentTeamId ? (params.opponentTeamId as string) : undefined,
        hasVideoUrl: params.hasVideoUrl ? (params.hasVideoUrl as string) === 'true' : undefined,
    }

    if (params.playerType === 'rebounder')
        return {
            ...filters,
            type: params.type as TeamStatsType | undefined,
            playerType: 'rebounder',
        }

    if (params.type === 'defense')
        return {
            ...filters,
            type: 'defense',
            playerType: params.playerType as 'defender' | undefined,
        }

    return {
        ...filters,
        type: 'offense',
        playerType: params.playerType as 'shooter' | 'passer' | undefined,
        primaryDefId: 'primaryDefId' in params ? (params.primaryDefId as string) : undefined,
    }
}

export const mapShotType: Record<ShotType, string> = {
    JUMPER: 'Jumper',
    POST: 'Post',
    FLOATER: 'Floater',
    LAYUP: 'Layup',
    HEAVE: 'Heave',
    DUNK: 'Dunk',
}

export const mapComplexShotType: Record<ComplexShotType, string> = {
    CATCH_AND_SHOOT: 'Catch and Shoot',
    CATCH_AND_SHOOT_ON_MOVE_LEFT: 'Catch and Shoot on Move Left',
    CATCH_AND_SHOOT_ON_MOVE_RIGHT: 'Catch and Shoot on Move Right',
    CATCH_AND_SHOOT_RELOCATING: 'Catch and Shoot Relocating',
    CUT_FLOATER: 'Cut Floater',
    CUT_LAYUP: 'Cut Layup',
    DRIVING_FLOATER: 'Driving Floater',
    DRIVING_LAYUP: 'Driving Layup',
    HEAVE: 'Heave',
    LOB: 'Lob',
    OVER_SCREEN: 'Over Screen',
    POST_LEFT: 'Post Left',
    POST_RIGHT: 'Post Right',
    PULLUP_JUMPER: 'Pullup Jumper',
    SHAKE_AND_RAISE: 'Shake and Raise',
    STANDSTILL_LAYUP: 'Standstill Layup',
    STEPBACK: 'Stepback',
    TIP: 'Tip',
}

export const mapShotZone: Record<ShotZone, string> = {
    RA: 'Restricted Area',
    KEY: 'Key',
    LEFT_CORNER_TWO: 'Left Corner Two',
    LEFT_WING_TWO: 'Left Wing Two',
    MIDDLE_TWO: 'Mid Range Two',
    RIGHT_WING_TWO: 'Right Wing Two',
    RIGHT_CORNER_TWO: 'Right Corner Two',
    LEFT_CORNER_THREE: 'Left Corner Three',
    LEFT_WING_THREE: 'Left Wing Three',
    MIDDLE_THREE: 'Mid Range Three',
    RIGHT_WING_THREE: 'Right Wing Three',
    RIGHT_CORNER_THREE: 'Right Corner Three',
    FAR: 'Far',
    BACKCOURT: 'Backcourt',
}
