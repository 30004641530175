import type { ShotFilterQueryParams } from '../../../shared/utils/shots'
import { use } from 'react'
import { getServerSession } from 'next-auth/next'
import { parseShotFilterQueryParams } from '../../../shared/utils/shots'
import { formatDateString, pastDate } from '../../../shared/utils/dates'
import { serverGetPlayerById } from '../server'
import { authOptions } from '@/lib/auth'
import { mapPlayerToSearchResult } from '@/lib/utils/search'

export type VideoHubFiltersFormValues = DTO.ShotFilters & {
    player?: DTO.SearchResult
    primaryDef?: DTO.SearchResult
}

export const useSuspenseVideoHubParams = (
    searchParams: ShotFilterQueryParams
): { shotFilters: VideoHubFiltersFormValues; key: string } => {
    const session = use(getServerSession(authOptions))

    const queryParams = { ...searchParams }
    const isGlgUser = !!session?.roles.contentPermissions['post-feed-glg']
    const isAmUser = !!session?.roles.contentPermissions['amateur-assignments'] && !isGlgUser
    // if minimum required params are not present, set defaults
    if (!queryParams.timeFrame) {
        const timeFrame: VideoHubFiltersFormValues['timeFrame'] = ['DATE_RANGE']
        queryParams.timeFrame = timeFrame
        queryParams.startDate = formatDateString(pastDate(1, 'day'), 'YYYY-MM-DD', 'local')
    }
    if (!queryParams.level) {
        const level: VideoHubFiltersFormValues['level'] = isAmUser ? 'FIBA' : 'NBA'
        queryParams.level = level
    }
    if (!queryParams.league) {
        let league: VideoHubFiltersFormValues['league'] = 'NBA'
        if (isGlgUser) league = 'G-League'
        else if (isAmUser) league = 'College'
        queryParams.league = league
    }

    const parsedQueryParams: VideoHubFiltersFormValues = parseShotFilterQueryParams(queryParams, false)
    parsedQueryParams.perPage = 40

    const player = use(
        parsedQueryParams.playerId
            ? serverGetPlayerById(parsedQueryParams.playerId, session)
            : Promise.resolve(undefined)
    )
    parsedQueryParams.player = player && mapPlayerToSearchResult(player)

    const primaryDef = use(
        parsedQueryParams.primaryDefId
            ? serverGetPlayerById(parsedQueryParams.primaryDefId, session)
            : Promise.resolve(undefined)
    )
    parsedQueryParams.primaryDef = primaryDef && mapPlayerToSearchResult(primaryDef)

    // create unique key so that the UI can re-render when the filters change
    const filterKey = Object.entries(parsedQueryParams)
        .filter(([key]) => key !== 'player' && key !== 'primaryDef')
        .map(([key, value]) => `${key}:${Array.isArray(value) ? value.sort().join(',') : (value as string | number)}`)
        .join('|')

    return {
        shotFilters: parsedQueryParams,
        key: filterKey,
    }
}
