import { useSuspenseInjuryHistory, useSuspenseCurrentInjuries, useSuspenseInjuryNews } from '../useInjuryHistory'
import { useSuspenseGetTeamByTeamId } from '../useTeam'
import { useSuspensePost } from '../usePost'
import { useSuspenseShots, useSuspenseShotDetails } from '../useShots'
import { useSuspenseGetPlayersById } from '../usePlayer'
import { useSuspenseVideoHubParams } from './useSuspenseVideoHubParams'

export {
    useSuspenseInjuryHistory,
    useSuspenseCurrentInjuries,
    useSuspenseInjuryNews,
    useSuspenseGetTeamByTeamId,
    useSuspensePost,
    useSuspenseShots,
    useSuspenseShotDetails,
    useSuspenseGetPlayersById,
    useSuspenseVideoHubParams,
}
