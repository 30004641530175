'use client'

import React, { Suspense, useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import Skeleton from '@mui/material/Skeleton'
import Divider from '@mui/material/Divider'
import CircleIcon from '@mui/icons-material/Circle'
import Chip from '@mui/material/Chip'
import { ascending } from 'd3'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import FormControl from '@mui/material/FormControl'

import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import Tooltip from '@mui/material/Tooltip'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import PlayerHeadshotAvatar from '../boards/PositionalBoard/PlayerHeadshotAvatar'
import { formatDateString, dateDiff } from '../../lib/utils/formatters'
import Link from '../Link'
import InjuryNewsDialog from '../injury/InjuryNewsDialog'
import PermissionContent from '../wrappers/PermissionContent'
import { useDepthChartTeams } from '@/lib/hooks/useDepthCharts'
import { useBreakPoints } from '@/lib/hooks'
import { useSuspenseCurrentInjuries } from '@/lib/hooks/suspense'

const InjuryWidgetSkeleton = () => (
    <Grid container paddingTop={1} paddingX={2}>
        <Grid item xs={12} display="flex" flexDirection="column" justifyContent="center">
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Box display="flex" gap={1}>
                    <Skeleton variant="circular" width={38} height={38} />
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                        <Skeleton variant="rectangular" width={75} height={12} />
                        <Skeleton variant="rectangular" width={150} height={12} />
                    </Box>
                </Box>
                <Skeleton variant="rectangular" width={100} height={20} />
            </Box>
        </Grid>
        <Divider sx={{ marginTop: 1, width: '100%' }} />
    </Grid>
)

type InjuryWidgetProps = {
    teamId?: string
    league?: Enum.League
}

type InjuryWidgetSuspenseProps = {
    selectedTeam: string | undefined
    selectedLeague: Enum.League | undefined
    isMobile: boolean
}
const InjuryWidgetSuspense = ({ selectedTeam, selectedLeague, isMobile }: InjuryWidgetSuspenseProps) => {
    const { data: currentInjuries } = useSuspenseCurrentInjuries({
        teamId: selectedTeam === 'All Teams' ? undefined : selectedTeam,
        league: selectedLeague,
    })

    const [selectedPlayerId, setSelectedPlayerId] = useState<string | null>(null)
    const [widgetWidth, setWidgetWidth] = useState(document.getElementById('injury-widget')?.offsetWidth)
    useEffect(() => {
        setWidgetWidth(document.getElementById('injury-widget')?.offsetWidth)
        function handleResize(this: Window) {
            setWidgetWidth(document.getElementById('injury-widget')?.offsetWidth)
        }
        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, [])

    const playerInjury = currentInjuries.find((d) => d.playerId === selectedPlayerId)
    return (
        <>
            {currentInjuries.length === 0 ? (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '100px',
                        width: '100%',
                    }}
                >
                    <Typography variant="h6" color="text.secondary">
                        No Current Injuries
                    </Typography>
                </Box>
            ) : (
                currentInjuries.map((d) => (
                    <Box key={d.playerId}>
                        <Box sx={{ paddingY: '2px', paddingX: 2 }}>
                            <Box gap={1} sx={{ display: 'flex', justifyContent: 'flex-start', width: '100%' }}>
                                {currentInjuries.length < 300 && (
                                    <Box sx={{ width: '50px', display: 'flex', alignItems: 'center' }}>
                                        <PlayerHeadshotAvatar
                                            outlined
                                            imageUrl={d.headshot}
                                            teamImageUrl={d.teamLogoUrl}
                                        />
                                    </Box>
                                )}
                                <Box sx={{ width: '100%' }}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            width: '100%',
                                            alignItems: 'baseline',
                                        }}
                                    >
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <Link
                                                    sx={{
                                                        fontWeight: 'medium',
                                                        display: 'flex',
                                                        gap: 0.5,
                                                        alignItems: 'center',
                                                    }}
                                                    href={`/players/${d.playerSlug}/injury`}
                                                    target="_blank"
                                                >
                                                    {d.playerName}
                                                    <OpenInNewIcon sx={{ fontSize: '14px' }} />
                                                </Link>
                                            </Box>
                                        </Box>
                                        <Chip
                                            sx={{
                                                width: '60px',
                                                height: '100%',
                                                marginTop: 0.5,
                                                fontSize: '12px',
                                                fontWeight: 'medium',
                                                backgroundColor: 'white',
                                                border: '1px solid',
                                                borderColor: 'divider',
                                                span: {
                                                    paddingX: 1,
                                                    width: '100%',
                                                },
                                            }}
                                            label={
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <CircleIcon
                                                        sx={{
                                                            padding: '0px',
                                                            fontSize: '10px',
                                                            color:
                                                                d.injuryStatus === 'OFS'
                                                                    ? 'injury.ofs'
                                                                    : d.injuryStatus === 'OUT'
                                                                    ? 'injury.out30'
                                                                    : 'injury.out7',
                                                        }}
                                                    />
                                                    <Box> {d.injuryStatus} </Box>
                                                </Box>
                                            }
                                        />
                                    </Box>
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <Box sx={{ display: 'flex' }}>
                                            {d.injuryStartDate && (
                                                <Typography
                                                    sx={{
                                                        color: 'text-secondary',
                                                        marginRight: '4px',
                                                        fontSize: '14px',
                                                    }}
                                                >
                                                    {formatDateString(d.injuryStartDate, 'MMM D, YYYY', 'local')}
                                                    {' | '}
                                                </Typography>
                                            )}

                                            <Typography
                                                color={d.injuryStartDate ? 'primary' : undefined}
                                                sx={{
                                                    cursor: d.injuryStartDate ? 'pointer' : undefined,
                                                    fontSize: '14px',
                                                    fontWeight: 'medium',
                                                    textOverflow: 'ellipsis',
                                                    maxWidth:
                                                        d.injuryReturnDate &&
                                                        d.injuryStatus !== 'DTD' &&
                                                        !dateDiff(d.injuryReturnDate, 'days', 0) &&
                                                        !isMobile
                                                            ? !widgetWidth || widgetWidth > 440
                                                                ? 165
                                                                : widgetWidth && widgetWidth > 390
                                                                ? widgetWidth / 3.8
                                                                : 75
                                                            : undefined,
                                                    whiteSpace: 'nowrap',
                                                    overflow: 'hidden',
                                                }}
                                                onClick={() => setSelectedPlayerId(d.playerId)}
                                            >
                                                {d.injury}
                                            </Typography>
                                        </Box>
                                        {d.injuryReturnDate &&
                                            d.injuryStatus !== 'DTD' &&
                                            !dateDiff(d.injuryReturnDate, 'days', 0) &&
                                            !isMobile && (
                                                <Box display="flex" flexDirection="row" gap={0.5} alignItems="center">
                                                    <Typography
                                                        sx={{
                                                            fontSize: '14px',
                                                        }}
                                                    >
                                                        Est.{' '}
                                                        {dateDiff(d.injuryReturnDate, 'days', -7)
                                                            ? '1-7 Days'
                                                            : dateDiff(d.injuryReturnDate, 'days', -30)
                                                            ? '7-30 Days'
                                                            : '30+ Days'}
                                                    </Typography>
                                                    <Tooltip
                                                        title={`Est. Return: ${formatDateString(
                                                            d.injuryReturnDate,
                                                            'MMM D, YYYY',
                                                            'local'
                                                        )}`}
                                                    >
                                                        <CalendarMonthIcon fontSize="inherit" />
                                                    </Tooltip>
                                                </Box>
                                            )}
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                        <Divider />
                    </Box>
                ))
            )}
            <InjuryNewsDialog
                injury={playerInjury?.injury}
                playerName={playerInjury?.playerName}
                injuryStartDate={playerInjury?.injuryStartDate}
                playerId={selectedPlayerId}
                setPlayerId={setSelectedPlayerId}
                status={playerInjury?.injuryStatus}
                playerSlug={playerInjury?.playerSlug}
                injuryReturnDate={playerInjury?.injuryReturnDate}
            />
        </>
    )
}

const InjuryWidget = ({ teamId, league }: InjuryWidgetProps): JSX.Element => {
    const [selectedLeague, setSelectedLeague] = useState<Enum.League | undefined>(league)
    const [selectedTeam, setSelectedTeam] = useState<string | undefined>(teamId || 'All Teams')
    const { data: teams } = useDepthChartTeams(selectedLeague)
    const { isMobile, isDesktop } = useBreakPoints()
    return (
        <PermissionContent type="injury-history">
            <Paper
                id="injury-widget"
                variant="outlined"
                sx={{ minWidth: '100%', height: !isDesktop ? window.innerHeight - 150 : '464px', overflow: 'hidden' }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        width: '100%',
                        height: '53px',
                        borderBottom: '1px solid',
                        borderColor: 'divider',
                        padding: 1,
                        paddingLeft: 2,
                        alignItems: 'center',
                        fontWeight: 'medium',
                        letterSpacing: '0.02857em',
                        fontSize: '.875rem',
                        color: 'rgba(0,0,0,.6)',
                        justifyContent: 'space-between',
                    }}
                >
                    <Box>{!isMobile ? 'CURRENT ' : ''}INJURIES</Box>
                    {!teamId && (
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            {teams && selectedLeague && ['NBA', 'G-League'].includes(selectedLeague) && (
                                <FormControl sx={{ maxWidth: '150px', minWidth: '75px' }}>
                                    <Select
                                        onChange={(e) => {
                                            setSelectedTeam(e.target.value)
                                        }}
                                        value={selectedTeam}
                                        MenuProps={{ style: { maxHeight: 400 } }}
                                        sx={{
                                            height: '30px',
                                            fontStyle: selectedTeam === 'All Teams' ? 'italic' : undefined,
                                            fontSize: '14px',
                                            padding: 0,
                                        }}
                                    >
                                        <MenuItem
                                            sx={{ fontStyle: 'italic', fontSize: '14px' }}
                                            key="All Teams"
                                            value="All Teams"
                                        >
                                            All Teams
                                        </MenuItem>
                                        {teams
                                            .sort((a, b) => ascending(a.abbr || '', b.abbr || ''))
                                            .map((o) => (
                                                <MenuItem key={o.teamId} value={o.teamId} sx={{ fontSize: '14px' }}>
                                                    {o.abbr}
                                                </MenuItem>
                                            ))}
                                    </Select>
                                </FormControl>
                            )}
                            <FormControl sx={{ maxWidth: '150px', minWidth: '75px', marginLeft: '10px' }}>
                                <Select
                                    onChange={(e) => {
                                        setSelectedLeague(e.target.value as Enum.League)
                                        setSelectedTeam('All Teams')
                                    }}
                                    value={selectedLeague}
                                    sx={{ height: '30px', fontSize: '14px' }}
                                >
                                    {['NBA', 'G-League', 'College', 'International'].map((o) => (
                                        <MenuItem sx={{ fontSize: '14px' }} key={o} value={o}>
                                            {o === 'G-League' ? 'GLG' : o}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                    )}
                </Box>
                <Box
                    sx={{ maxHeight: !isDesktop ? window.innerHeight - 210 : '410px', paddingTop: 0, overflow: 'auto' }}
                >
                    <Suspense
                        fallback={
                            <>
                                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((x) => (
                                    <InjuryWidgetSkeleton key={x} />
                                ))}
                            </>
                        }
                    >
                        <InjuryWidgetSuspense
                            selectedTeam={selectedTeam}
                            selectedLeague={selectedLeague}
                            isMobile={isMobile}
                        />
                    </Suspense>
                </Box>
            </Paper>
        </PermissionContent>
    )
}

export default InjuryWidget
